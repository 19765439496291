import Product from "../../../apis/Product";
// const setWithExpiryInMinutes = 100000;
const setWithExpiryInMinutes = 2;

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
        return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
    window.local_storage_expiry = item.expiry;
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	return item.data;
}

function setWithExpiry(key, data, ttl) {
	const now = new Date();
	const item = {
		data: data,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item));
}

function allStorageValues() {
    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
    while (i--) {
        values.push( localStorage.getItem(keys[i]) );
    }
    return values;
}

function allStorageKeys() {
    var keys = Object.keys(localStorage);
    return keys;
}

// Remove all other locally stored products from other offers.
const localStorageKeys = allStorageKeys();
const _keys = localStorage.length;
let i = 0;
for (i = 0; i < _keys; i++) {
    if (!localStorageKeys[i].includes(window.APP_API_CUSTOMER)) {
        localStorage.removeItem(localStorageKeys[i]);
    }
}

function deleteLocalStorageWithKey(key) {
    const localStorageKeys = allStorageKeys();
    const _keys = localStorage.length;
    let i = 0;
    for (i = 0; i < _keys; i++) {
        if (localStorageKeys[i].includes(window.APP_API_CUSTOMER)) {
            // localStorage.removeItem(localStorageKeys[i]);
        }
    }
}

export const getProducts = ({ commit }, productId) => {
    let _products = getWithExpiry(window.APP_API_CUSTOMER);
    // if (_products.status == 'open') {
        // deleteLocalStorageWithKey(window.APP_API_CUSTOMER);
    // }
    if (_products) {
        new Promise((resolve) => {
            resolve(_products);
        })
        .then(data => {
            // Stats start.
            // let _now = new Date;
            // _now = _now.getTime();
            // let _seconds = Math.round((window.local_storage_expiry - _now) / 1000);
            // console.log('Source: from local storage overview, TTL: ' + _seconds + 's (' + Math.round(_seconds / 60) + 'm).');
            console.log('Source: from local storage overview.');
            // Stats end.
            let languages = data.languages;
            let defaultLanguage = data.defaultLanguage;
            let deliveryOptions = data.deliveryOptions;
            let status = data.status;
            let openDateTime = data.openDateTime;
            let closeDateTime = data.closeDateTime;
            let logoUrl = data.logoUrl;
            let theme = data.theme;
            let checkoutFields = data.checkoutFields;
            let responseCode = data.responseCode;
            let responseData = (data.responseCode == 403) ? data.responseData : 'received';
            let showStockNumber = data.showStockNumber;
            let products = {};
            if ('products' in data) {
                products = data.products.filter(product => product.id != productId);
                products = {products, languages, defaultLanguage, deliveryOptions, status, openDateTime, closeDateTime, logoUrl, theme, checkoutFields, responseCode, responseData, showStockNumber};
            }
            else {
                if (responseCode != 403) {
                    products = {products, languages, defaultLanguage, deliveryOptions, status, openDateTime, closeDateTime, logoUrl, theme, checkoutFields, responseCode, responseData, showStockNumber};
                }
                else {
                    products = {responseCode, responseData};
                }
            }
            commit('SET_PRODUCTS', products);
        })
        .then(() => {
            window.PRODUCTS_LOADED = true;
        })
    }
    else {
        Product.all()
        .then(response => {
            console.log('Source: all products endpoint.');
            // let products = response.data.products.filter(product => product.id != productId);
            // if (response.status == 200) {
            let products = response.data.products;
            let languages = response.data.languages;
            let defaultLanguage = response.data.defaultLanguage;
            let deliveryOptions = response.data.deliveryOptions;
            let status = response.data.status;
            let openDateTime = response.data.openDateTime;
            let closeDateTime = response.data.closeDateTime;
            let logoUrl = response.data.logoUrl;
            let theme = response.data.theme;
            let checkoutFields = response.data.checkoutFields;
            let responseCode = response.status;
            let responseData = (responseCode == 403) ? response.data.replace(/\r?\n|\r/g, '') : 'received';
            let title = response.data.title;
            let showStockNumber = response.data.showStockNumber;
            window.APP_TITLE = title;
            products = {products, languages, defaultLanguage, deliveryOptions, status, openDateTime, closeDateTime, logoUrl, theme, checkoutFields, responseCode, responseData, title, showStockNumber};
            setWithExpiry(window.APP_API_CUSTOMER, products, setWithExpiryInMinutes * 60 * 1000);
            commit('SET_PRODUCTS', products);
            // }
            if (response.status == 403 || response.status == 404) {
                window.APP_API_ERROR = window.APP_RESPONSE;
                window.APP_RESPONSE = response.data.replace(/\r?\n|\r/g, '');
                commit('SET_STATUS', APP_RESPONSE);
            }
        })
        .then(() => {
            window.PRODUCTS_LOADED = true;
            console.log('Data requested.');
        })
        .catch(error => {
            window.APP_NO_OFFER = true;
            window.APP_ERROR = error;
            console.log('Error occurred.');
        })
    }
}

export const getProduct = ({ commit }, productId) => {
    let _product = getWithExpiry(window.APP_API_CUSTOMER + '_' + productId);
    // console.log({_product});
    if (_product) {
        new Promise((resolve) => {
            resolve(_product);
        })
        .then(data => {
            // Stats start.
            console.log('Source: from local storage product: ' + productId + '.');
            // Stats end.
            commit('SET_PRODUCT', data);
        })
        .then(() => {
            window.PRODUCT_LOADED = true;
        })
    }
    else {    
        Product.show(productId)
        .then(response => {
            console.log('Source: product endpoint.');
            setWithExpiry(window.APP_API_CUSTOMER + '_' + response.data.id, response.data, setWithExpiryInMinutes * 60 * 1000);
            commit('SET_PRODUCT', response.data);
        })
        .then(() => {
            window.PRODUCT_LOADED = true;
        })
    }
}

export const getLabels = ({ commit }) => {
    let _labels = getWithExpiry(window.APP_API_CUSTOMER + '_labels');
    if (_labels) {
        new Promise((resolve) => {
            resolve(_labels);
        })
        .then(data => {
            // Stats start.
            console.log('Source: from local storage labels.');
            // Stats end.
            commit('SET_LABELS', data);
        })
        .then(() => {
            window.LABELS_LOADED = true;
        })
    }
    else {    
        Product.labels()
        .then(response => {
            console.log('Source: labels endpoint.');
            setWithExpiry(window.APP_API_CUSTOMER + '_labels', response.data, setWithExpiryInMinutes * 60 * 1000);
            commit('SET_LABELS', response.data);
        })
        .then(() => {
            window.LABELS_LOADED = true;
        })
    }
}