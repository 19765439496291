<template>
  <div class="row">
    <div v-for="(product, index) in products.products" :key="index" class="product col-md-3 col-sm-4">
      <div class="thumbnail">
        <div class="badges position-absolute m-2">
          <img v-for="(badge, index) in product['badges']" :key="index" :src="badge" class="hidden-xs mb-1" />
        </div>
        <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">
          <img class="product-thumbnail w-100" :src="product.thumbnail" />
        </a>
        <div class="caption p-2">
          <span v-if="'brand' in product" class="font-weight-bold">
            <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">{{product.brand.name}}</a>
          </span>
          <h5>
            <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'id': getAttribute('customer_id'), 'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">{{product.title}}</a>
          </h5>
          <p v-if="products.showStockNumber">
            {{labels.inStock}}: {{product.stock}}
          </p>
          <p v-if="labels" class="text-center mt-3">
            <a :href="$router.resolve({name: 'product', params: {id: product.id}, query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href" :title="product.title">{{labels.viewGift}}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '../plugins/translations';
import utils from '@/plugins/utils'

export default {
  props: ['products', 'labels'],
  created() {
    this.loading = true;
    this.lang = window.APP_API_LANG;
    this.cur = window.APP_API_CUR;
    this.translations = translations;
  },
  mounted() {
  },
  computed: {
  },
  beforeUpdate() {
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
  },

};
</script>

<style>
</style>