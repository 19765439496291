import Home from "./pages/Home.vue";
import Product from "./pages/Product.vue";
import Checkout from "./pages/Checkout.vue";
import Confirmation from "./pages/Confirmation.vue";

export default [
    {
        path: '/:appid/',
        component: Home,
        name: 'home',
        props: true
    },
    {
        path: '/:appid/product/:id',
        component: Product,
        name: 'product',
        props: true
    },
    {
        path: '/:appid/checkout/:id',
        component: Checkout,
        name: 'checkout',
        props: true
    },
    {
        path: '/:appid/confirmation/',
        component: Confirmation,
        name: 'confirmation',
        props: true
    }
]