<template>
  <div class="fade-in-slow">
    <footer>
      <div class="tagline" :class="products.theme">
        <div class="container text-center">
          {{t(this.lang, 'footer-difference')}}
        </div>
      </div>
      <div class="contact m-4">
        <div class="container text-center address">
          Baxx Promotion A/S - Marsvej 26, 6000 Kolding - <a href="tel:75 52 50 00">75 52 50 00</a> - <a href="mailto:baxx@baxxpromotion.com">baxx@baxxpromotion.com</a> - CVR <a href="https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=24210766&language=da">24210766</a>
          <br>
          METZ A/S - Skelstedet 9, 2950 Vedbæk - <a href="tel:44 82 72 00">44 82 72 00</a> - <a href="mailto:info@metz.dk">info@metz.dk</a> - CVR <a href="https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=27171508&language=da">27171508</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import translations from '../plugins/translations';
import utils from '@/plugins/utils'
export default {
  props: ["products"],
  created() {
    this.lang = window.APP_API_LANG;
    this.translations = translations;
  },  
  methods: {
    getLang: utils.getLang,
    t: utils.t
  }
};
</script>

<style>
.address a {
  text-decoration: none;
  font-weight: 700;
}
.tagline {
  align-content: center;
  align-items: center;  
  background-color: #062a39;
  color: #fff;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  height: 100px;
  letter-spacing: 3px;
}

.tagline .container {
  background-color: #062a39;
}

.tagline.xmas,
.tagline.xmas-xtra {
  background-image: none!important;
  background-color: #62130e;
  color: #fff;
}

.tagline.xmas,
.tagline.xmas .container {
  background-color: #d0dbdd;
}

.tagline.xmas-xtra .container {
  background-color: #62130e;
}
</style>