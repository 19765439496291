<template>
  <div :class="products.theme" class="confirmation"> 
    {{getSetTitleFromWindow()}}
    <div v-if="noOffer"></div>

    <div v-if="loading" class="loading-spinner"><div></div><div></div><div></div><div></div></div>
    
    <div class="container confirm">
      <div class="row">

        <div v-if="confirmation.response == 'success'">
          <h3 class="mt-3">{{labels.receiptHeader}}</h3>
          <p class="mt-3">{{labels.receiptBody}}</p>
          <div class="row" v-for="(data, index) in confirmation.registration" :key="index">
            <div class="col">
              <span v-if="data.label == 'giftCardCode'">
                {{labels['giftcardCode']}}
              </span>
              <span v-else>
                {{labels[data.label]}}
              </span>
            </div>
            <div class="col-9">{{data.value}}</div>
          </div>
          <div v-if="confirmation.privateShopUrl">
            <h5 class="modal-title mt-4">{{labels.privateShopHeader}}</h5>
            <p v-html="this.replaceToken(labels.privateShopBody, confirmation.privateShopUrl, '{{PrivateShopUrl}}')"></p>
          </div>
        </div>         

      </div>

      <br><br>
    </div>

  </div>
</template>

<script>
import translations from '@/plugins/translations';
import utils from '@/plugins/utils'

export default {
  props: ['products', 'labels'],
  data: function() {
    return {
      noOffer: false,
      apiError: false,
      apiResponse: window.APP_RESPONSE,
      confirmation: {
        /*
        "response": "success",
        "registration": [
            {
                "label": "receiptGiftChoice",
                "value": "Højtaler bluetooth Flip III + Stelton Click termokrus"
            },
            {
                "label": "giftCardCode",
                "value": "1875ECXI"
            },
            {
                "label": "fullName",
                "value": "Test Tester"
            },
            {
                "label": "email",
                "value": "asdf.qwer@gmail.com"
            },
            {
                "label": "mobileNumber",
                "value": "21212121"
            }
        ],
        "privateShopUrl": "https://shop.metz.dk/privatshop/da&ref=cs"
        */
      },
    }
  },
  created() {
    this.loading = true;
    let urlParams = new URLSearchParams(window.location.search);
    this.lang = (urlParams.get('lang') == null) ? window.APP_API_LANG : urlParams.get('lang');
    this.cur = (urlParams.get('cur') == null) ? window.APP_API_CUR : urlParams.get('cur');
    this.translations = translations;
  },  
  mounted() {
    this.$nextTick(() => {
      this.loading = false;
      this.checkResponse();
      this.initWindowSizeCalc();
      this.getConfirmation();
    })
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,
    replaceToken: utils.replaceToken,

    getConfirmation() {
      this.confirmation = this.getWithExpiry(window.APP_API_CUSTOMER + '_confirmation');
    },

    initWindowSizeCalc() {
      $(function() {
        setTimeout(function() {
          let _element = $('.container.confirm');
          let _window_height = $(window).height();
          let _app_height = $('#app').height();
          if (_window_height > _app_height) {
            let diff = _window_height - _app_height;
            _element.css('padding-bottom', ((diff - 20)) + 'px');
          }
        }, 300);
      });
    },

    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
            return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
        window.local_storage_expiry = item.expiry;
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.data;
    },  

    getSetTitleFromWindow() {
      const metaTitle = document.querySelector('head title');
      if (typeof window.APP_TITLE != 'undefined') {
        metaTitle.textContent = window.APP_TITLE + ' - BAXX & METZ';
      }
      else {
        metaTitle.textContent = this.getTitle();
      }
    },

    checkOffer() {
      this.noOffer = window.APP_NO_OFFER;
    },

    checkError() {
      this.apiError = window.APP_API_ERROR;
    },

    checkResponse() {
      this.apiResponse = window.APP_RESPONSE;
    },

  }
};
</script>

<style>
.xmas-xtra h1,
.xmas h1 {
  font-family: 'Abril Fatface'!important;
}

.home.xmas .container.overview,
.home.xmas-xtra .container.overview {
  background-color: transparent;
}

.home.xmas,
.home.xmas h3,
.home.xmas-xtra,
.home.xmas-xtra h3,
.c2 .container {
  color: #fff;
}
.container.confirm {
  color: #000;
}
.container.confirm h3 {
  color:#000;
}
@media (max-width: 768px) {
  .row.other-products {
    margin: 0;
  }
  h3 {
    margin-left: 15px;
  }
}
[v-cloak] {
  display: none;
}
</style>