export default {
  'da': {
    'pageNotFoundHeader': 'Tilbuddet er ikke tilgængeligt',
    'pageNotFoundBody': 'Hvis du har spørgsmål, kontakt venligst <a href="mailto:support@metz.dk">Metz Support</a>.',
    'welcome-to': 'Velkommen hos',
    'xmas': 'Julen 2021',
    'frontpage': 'Forside',
    'categories': 'KATEGORIER',
    'meta-title': 'Metz produktoversigt',
    'search': 'Søg',
    'back': 'TILBAGE',
    'products': 'Produkter',
    'related-products': 'Relaterede produkter',
    'danish-crone': 'Danske Kroner',
    'currency': 'DKK',
    'currency-name': 'Valuta',
    'contact': 'Kontakt',
    'offer': 'Tilbud',
    'csr': 'Metz CSR politik',
    'about': 'Om Metz',
    'language': 'Sprog',
    'sort': 'SORTER',
    'all': 'Alle',
    'a-z': 'A-Å',
    'z-a': 'Å-A',
    'price-low-high': 'Pris - høj til lav',
    'price-high-low': 'Pris - lav til høj',
    'target-price': 'Vejl. pris',
    'your-price': 'Din pris',
    'incl-vat': 'inkl. moms',
    'ex-vat': 'ekskl. moms',
    'at': 'ved',
    'pcs': 'stk',
    'item-no': 'Varenr',
    // 'item-color': 'Farve',
    // 'item-size': 'Størrelse',
    'retail-price': 'Vejl. pris',
    'read-more': 'Læse mere',
    'description': 'Beskrivelse',
    'specifications': 'Specifikationer',
    'about-product': 'Om',
    'footer-difference': 'EN FORSKEL DU BLIVER HUSKET FOR',
    'no-product': 'Produktet findes desværre ikke.',
    'status-pending': 'Shoppen åbner',
    'status-closed': 'Shoppen er lukket',

    'ApproveHeadline': 'Godkend dit valg',
    'IntroText': 'Kære kollega    <br />    <br />Det er nu tid til at vælge din gave.    <br />    <br />Udfyld venligst nedenstående formular med dine personlige    oplysninger.',
    'GiftCardCode': 'Gavekortkode',
    'GiftCardCodePlaceholder': 'Indtast din gavekortkode',
    'Fullname': 'Navn',
    'FullnamePlaceholder': 'Indtast dit fulde navn',
    'Email': 'Email',
    'EmailPlaceholder': 'Indtast din email adresse',
    'EmployeeNumber': 'Medarbejdernr.',
    'EmployeeNumberPlaceholder': 'Indtast dit medarbejdernr.',
    'MobileNumber': 'Mobilnummer',
    'MobileNumberPlaceholder': 'Indtast mobilnummer for at modtage en sms når pakken er leveret',
    'ShippingOptionsShop': 'Levering til pakkeshop',
    'ShippingOptionsShopStreet': 'Vejnavn',
    'ShippingOptionsShopPostalCode': 'Postnr.',
    'ShippingOptionsShopCountry': 'Land',
    'ShippingOptionsPrivate': 'Levering til privatadresse',
    'ShippingPrivateName': 'Navn',
    'ShippingPrivateAddress1': 'Adresse 1',
    'ShippingPrivateAddress2': 'Adresse 2',
    'ShippingPrivatePostalCode': 'Postnr.',
    'ShippingPrivateCity': 'By',
    'ShippingPrivateCountry': 'Land',
    'Submit': 'Godkend dit valg',
    'Cancel': 'Annuller'
  },
  'en': {
    'no-offer': 'No offer available.',
    'welcome-to': 'Welcome to',
    'xmas': 'Christmas 2021',
    'frontpage': 'Frontpage',
    'categories': 'CATEGORIES',
    'meta-title': 'Metz products overview',
    'search': 'Search',
    'back': 'BACK',
    'products': 'Products',
    'related-products': 'Related products',
    'danish-crone': 'Danish Krone',
    'currency': 'EUR',
    'currency-name': 'Currency',
    'contact': 'Contact',
    'offer': 'Offer',
    'csr': 'Metz CSR policy',
    'about': 'About Metz',
    'language': 'Language',
    'sort': 'SORT',
    'all': 'All',
    'a-z': 'A-Z',
    'z-a': 'Z-A',
    'price-low-high': 'Price - high to low',
    'price-high-low': 'Price - low to high',
    'your-price': 'Your price',
    'incl-vat': 'incl. VAT',
    'ex-vat': 'excl. VAT',
    'at': 'at',
    'pcs': 'pcs',
    'item-no': 'Item no.',
    // 'item-color': 'Color',
    // 'item-size': 'Size',
    'retail-price': 'Retail price',
    'read-more': 'Read more',
    'description': 'Description',
    'specifications': 'Specifications',
    'about-product': 'About',
    'footer-difference': 'GIFTS YOU WILL BE REMEMBERED FOR',
    'no-product': 'The product does not exist.',
    'status-pending': 'Shop will open',
    'status-closed': 'Shop is closed',

    'ApproveHeadline': 'Confirm your choice',
    'IntroText': 'Please fill out the form below with your personal information.',
    'GiftCardCode': 'Voucher code',
    'GiftCardCodePlaceholder': 'Enter your voucher code',
    'Fullname': 'Name',
    'FullnamePlaceholder': 'Enter your full name',
    'Email': 'Email',
    'EmailPlaceholder': 'Enter your email address',
    'EmployeeNumber': 'Employee number',
    'EmployeeNumberPlaceholder': 'Enter your employee number',
    'MobileNumber': 'Mobile number',
    'MobileNumberPlaceholder': 'Enter mobile number to receive a message when the package is delivered',
    'ShippingOptionsShop': 'Delivery to parcel shop',
    'ShippingOptionsShopStreet': 'Street',
    'ShippingOptionsShopPostalCode': 'Postal Code',
    'ShippingOptionsShopCountry': 'Country',
    'ShippingOptionsPrivate': 'Delivery to private address',
    'ShippingPrivateName': 'Name',
    'ShippingPrivateAddress1': 'Addresse 1',
    'ShippingPrivateAddress2': 'Addresse 2',
    'ShippingPrivatePostalCode': 'Postal code',
    'ShippingPrivateCity': 'City',
    'ShippingPrivateCountry': 'Country',
    'Submit': 'Confirm your selection',
    'Cancel': 'Cancel'
  }
};