import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'
import routes from "./routes";
import store from "./store";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

require('@/assets/js/jquery.royalslider.min.js');

Vue.use(VueRouter);

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {  
  if (!to.query.lang) {
    to.query.lang = window.APP_API_LANG;
    to.query.cur = window.APP_API_CUR;
    // Wait for component to load before going to the top.
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);    
    next({ path: to.path, query: to.query });
  } else {
    next();
  }
});

Vue.config.productionTip = false

Vue.mixin({
  data: function() {
    return {
      // get offerID() {
        // return "abcdefg";
      // }
    }
  }
})

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
