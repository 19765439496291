let utils = {
  getLang() {
    return this.lang;
  },

  t(lang, translation) {
    return this.translations[lang][translation];
  },

  getAttribute(attr) {
    if (attr == 'cur') return this.cur;
    if (attr == 'lang') return this.lang;
  },

  formatNumber(num, fraction_digits = 2) {
    let _locale = {
      'da': 'da-DK',
      'en': 'en-GB'
    }
    return Number(num/100).toLocaleString(_locale[this.getLang], {minimumFractionDigits: fraction_digits});
  },

  checkObject(obj) {
    if (typeof obj == 'string') {
      return 0;
    }
  },

  showLang(lang) {
    if (lang == 'da') return '🇩🇰';
    if (lang == 'en') return '🇬🇧';
  },
  
  showCur(cur) {
    if (cur == 'dkk') return '🇩🇰';
    if (cur == 'eur') return '🇪🇺';
  },

  getWindowWidth() {
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width;
  
  },

  getWindowHeight() {
    const height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
    return height;
  },

  getTitle() {
    const localStorageLabels = localStorage.getItem(window.APP_API_CUSTOMER + '_labels');
    if (!localStorageLabels) {
      return null;
    }
    const localStorageLabelsParsed = JSON.parse(localStorageLabels);
    if (!localStorageLabelsParsed.data.pageTitle) {
      return 'BAXX & METZ';
    }
    else {
      return `${localStorageLabelsParsed.data.pageTitle}`;
    }
  },
  
  setTitle() {
    const metaTitle = document.querySelector('head title');
    metaTitle.textContent = this.getTitle();
  },

  returnPartOfString(string, start, length) {
    return string.substring(start, length);
  },

  replaceToken(label, text, token) {
    if (typeof label == 'undefined') {
      return;
    }
    // console.log({label, text, token});
    return label.replace(token, text);
  },

}

export default utils