<template>
  <div :class="products.theme"> 
    {{getSetTitleFromWindow()}}
    <div class="container checkout-details">
      <div class="mt-3 mb-3"><a class="back" :href="$router.resolve({name: 'home', query: {'lang': getAttribute('lang'), 'cur': getAttribute('cur')}}).href">{{t(getLang(), 'back')}}</a></div>

      <div class="row prod-data-container" v-if="product && checkObject(product) != 0">
        
        <div class="col-md-6 mt-2">

          <div class="row">
            <div class="col-sm-9">
              <div v-if="labels">
                <h2 v-html="replaceToken(labels.confirmHeader, product.title, '{{GiftTitle}}')"></h2>
                <p v-html="labels.confirmBody"></p>
              </div>
            </div>
            <div class="hidden-xs col-sm-3">
              <div>
                <img :src="product.images[0].small" class="img-fluid" />
              </div>
            </div>
          </div>

          <div class="row form-container">
            <div class="col-sm-12">
              <!-- https://vuejs.org/v2/cookbook/form-validation.html -->
              <form @submit.prevent="submitForm" role="form" id="confirmForm" novalidate="true">

                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </p>

                <div v-if="product.variants" class="form-group">
                  <label for="variants" class="control-label">{{labels.variant}} *</label>
                  <select class="form-select" v-model="form.Variants" name="Variants" id="variants" required>
                    <option disabled value="">{{labels.variantPlaceholder}}</option>
                    <option v-for="(variant, index) in product.variants" :key="index" :value="variant.value">{{variant.title}}</option>
                  </select>
                </div>

                <div v-if="this.showField(products.checkoutFields, 'GiftCardCode')" class="form-group">
                  <label for="giftcard-code" class="control-label">{{labels.giftcardCode}}</label><span v-if="this.isRequired(products.checkoutFields, 'GiftCardCode')"> *</span>
                  <input type="text" class="form-control _is-valid _is-invalid" name="GiftCardCode" id="giftcard-code" v-model="form.GiftCardCode" :placeholder="labels.giftcardCodePlaceholder" :required="this.isRequired(products.checkoutFields, 'GiftCardCode')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'FullName')" class="form-group">
                  <label for="fullname" class="control-label">{{labels.fullName}}</label><span v-if="this.isRequired(products.checkoutFields, 'FullName')"> *</span>
                  <input type="text" class="form-control" name="FullName" id="FullName" v-model="form.FullName" :placeholder="labels.fullNamePlaceholder" :required="this.isRequired(products.checkoutFields, 'FullName')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'Initials')" class="form-group">
                  <label for="fullname" class="control-label">{{labels.initials}}</label><span v-if="this.isRequired(products.checkoutFields, 'Initials')"> *</span>
                  <input type="text" class="form-control" name="Initials" id="Initials" v-model="form.Initials" :placeholder="labels.initialsPlaceholder" :required="this.isRequired(products.checkoutFields, 'Initials')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'Attention')" class="form-group">
                  <label for="Attention" class="control-label">{{labels.attention}}</label><span v-if="this.isRequired(products.checkoutFields, 'Attention')"> *</span>
                  <input type="text" class="form-control" name="Attention" id="Attention" v-model="form.Attention" :placeholder="labels.attentionPlaceholder" :required="this.isRequired(products.checkoutFields, 'Attention')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'CostCenter')" class="form-group">
                  <label for="costCenter" class="control-label">{{labels.costCenter}}</label><span v-if="this.isRequired(products.checkoutFields, 'CostCenter')"> *</span>
                  <input type="text" class="form-control" name="CostCenter" id="CostCenter" v-model="form.CostCenter" :placeholder="labels.CostCenterPlaceholder" :required="this.isRequired(products.checkoutFields, 'CostCenter')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'Email')" class="form-group">
                  <label for="email" class="control-label">{{labels.email}}</label><span v-if="this.isRequired(products.checkoutFields, 'Email')"> *</span>
                  <input type="email" class="form-control" name="Email" id="email" :placeholder="labels.emailPlaceholder" v-model="form.Email" :required="this.isRequired(products.checkoutFields, 'Email')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'EmployeeNumber')" class="form-group">
                  <label for="employee-number" class="control-label" >{{labels.employeeNumber}}</label><span v-if="this.isRequired(products.checkoutFields, 'EmployeeNumber')"> *</span>
                  <input type="text" class="form-control" name="EmployeeNumber" id="employee-number" :placeholder="labels.employeeNumberPlaceholder" v-model="form.EmployeeNumber" :required="this.isRequired(products.checkoutFields, 'EmployeeNumber')" />
                </div>

                <div v-if="this.showField(products.checkoutFields, 'MobileNumber')" class="form-group">
                  <label for="mobile-number" class="control-label">{{labels.mobileNumber}}</label><span v-if="this.isRequired(products.checkoutFields, 'MobileNumber')"> *</span>
                  <input type="text" class="form-control" name="MobileNumber" id="mobile-number" :placeholder="labels.mobileNumberPlaceholder" v-model="form.MobileNumber" :required="this.isRequired(products.checkoutFields, 'MobileNumber')">
                </div>
                
                <div v-if="this.showField(products.checkoutFields, 'Custom1')" class="form-group">
                  <label for="Custom1" class="control-label">{{labels.custom1}}</label><span v-if="this.isRequired(products.checkoutFields, 'Custom1')"> *</span>
                  <div v-if="!this.hasOptions(products.checkoutFields, 'Custom1')">
                    <input type="text" class="form-control" name="Custom1" id="custom1" :placeholder="labels.custom1Placeholder" v-model="form.Custom1" :required="this.isRequired(products.checkoutFields, 'Custom1')">
                  </div>
                  <div v-else>
                    <select class="form-select" name="Custom1" v-model="form.Custom1" :required="this.isRequired(products.checkoutFields, 'Custom1')" id="custom1">
                      <option disabled value="">{{labels.custom1Placeholder}}</option>
                      <option v-for="(option, index) in this.hasOptions(products.checkoutFields, 'Custom1')" :key="index" :value="option">{{option}}</option>
                    </select>
                  </div>
                </div>

                <div v-if="this.showField(products.checkoutFields, 'Custom2')" class="form-group">
                  <label for="Custom2" class="control-label">{{labels.custom2}}</label><span v-if="this.isRequired(products.checkoutFields, 'Custom2')"> *</span>
                  <div v-if="!this.hasOptions(products.checkoutFields, 'Custom2')">
                    <input type="text" class="form-control" name="Custom2" id="custom2" :placeholder="labels.custom1Placeholder" v-model="form.Custom2" :required="this.isRequired(products.checkoutFields, 'Custom2')">
                  </div>
                  <div v-else>
                    <select class="form-select" name="Custom2" v-model="form.Custom1" :required="this.isRequired(products.checkoutFields, 'Custom2')" id="custom2">
                      <option disabled value="">{{labels.custom1Placeholder}}</option>
                      <option v-for="(option, index) in this.hasOptions(products.checkoutFields, 'Custom2')" :key="index" :value="option">{{option}}</option>
                    </select>
                  </div>
                </div>

                <div v-if="this.showField(products.checkoutFields, 'Location')" class="form-group location-group"> {{labels.locationPlaceholder}} *
                  <div v-if="getLocation(products.checkoutFields, labels)">
                    <select class="form-select" @change="onchangeLocation($event)" name="Location" v-model="form.Location" :required="this.isRequired(products.checkoutFields, 'Location')" id="location">
                      <option disabled value="">{{labels.locationPlaceholder}}</option>
                      <option v-for="(location, index) in getLocation(products.checkoutFields, labels)" :key="index" :value="index">{{location}}</option>
                    </select>
                  </div>
                  <div v-else>
                    <input type="text" class="form-control" name="Location" v-model="form.Location" :required="this.isRequired(products.checkoutFields, 'Location')" id="location">
                  </div>
                </div>

                <div v-if="products.deliveryOptions" id="shipping-options" class="mb-3">
                  <h3>{{labels.shippingOptionsDelivery}}</h3>
                  <div v-if="products.deliveryOptions.deliveryType.includes('shop')" class="radio pe-3" style="display:inline-block">
                    <label>
                      <input type="radio" @change="onchangeShippingOptions($event)" name="ShippingOptions" id="shipping-options-shop" value="shop" v-model="form.ShippingOptions">
                      {{labels.shippingOptionsShop}}
                    </label>
                  </div>
                  <div v-if="products.deliveryOptions.deliveryType.includes('company')" class="radio pe-3" style="display:inline-block">
                    <label>
                      <input type="radio" @change="onchangeShippingOptions($event)" name="ShippingOptions" id="shipping-options-company" value="company" v-model="form.ShippingOptions">
                      <!-- {{labels.shippingOptionsCompany}} -->
                      {{labels.shippingOptionsCompanyAddress}}
                    </label>
                  </div>
                  <div v-if="products.deliveryOptions.deliveryType.includes('private')" class="radio" style="display:inline-block">
                    <label>
                      <input type="radio" @change="onchangeShippingOptions($event)" name="ShippingOptions" id="shipping-options-private" value="private" v-model="form.ShippingOptions">
                      <!-- {{labels.shippingOptionsPrivate}} -->
                      {{labels.shippingOptionsPrivateAddress}}
                    </label>
                  </div>

                  <div id="shipping-wrapper" class="mt-3 collapse show">
                    
                    <div id="shipping-company-private" class="collapse mt-3 collapse">
                      <div id="shipping-specific-company" class="collapse">
                        <div class="form-group">
                          <label for="Company" class="control-label">{{labels.companyName}}</label><span> *</span>
                          <input type="text" class="form-control" name="Company" id="Company" autocomplete="false" v-model="form.Company">
                        </div>
                        <div class="form-group">
                          <label for="Attention" class="control-label">{{labels.attention}}</label><span> *</span>
                          <input type="text" class="form-control" name="Attention" id="Attention" autocomplete="false" v-model="form.Attention">
                        </div>
                      </div>
                      <div id="shipping-specific-private" class="collapse">
                        <div class="form-group">
                          <label for="shipping-full-name" class="control-label">{{labels.fullName}}</label><span> *</span>
                          <input type="text" class="form-control" name="ShippingName" id="shipping-full-name" autocomplete="false" v-model="form.ShippingName">
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="shipping-address1" class="control-label">{{labels.address1}}</label><span> *</span>
                        <input type="text" class="form-control" name="Address1" id="shipping-address1" autocomplete="false" v-model="form.ShippingAddress1">
                      </div>
                      <div class="form-group">
                        <label for="shipping-address2" class="control-label">{{labels.address2}}</label>
                        <input type="text" class="form-control" name="Address2" id="shipping-address2" autocomplete="false" v-model="form.ShippingAddress2">
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="PostalCode" class="control-label">{{labels.postalCode}}</label><span> *</span>
                            <input type="text" class="form-control" name="PostalCode" id="shipping-postal-code" autocomplete="false" v-model="form.ShippingPostalCode">
                          </div>
                        </div>
                        <div class="col-sm-8">
                          <div class="form-group">
                            <label for="shipping-city" class="control-label">{{labels.city}}</label>
                            <input type="text" class="form-control" name="ShippingCity" id="shipping-city" autocomplete="false" v-model="form.ShippingCity">
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="shipping-country">{{labels.country}}</label><span> *</span>
                        <select class="form-select" @change="onchangeCountry($event, products.deliveryOptions.countryOptions)" name="ShippingCountry" id="shipping-country" v-model="form.ShippingCountry" required>
                          <!-- <option value="DK" selected="">Danmark</option> -->
                          <option value="none">{{labels.countryPlaceholder}}</option>
                          <option v-for="(country, index) in products.deliveryOptions.countryOptions" :key="index" :value="country.countryCode">
                            <span v-if="country.countryName">{{country.countryName}}</span>
                            <span v-else>{{country.countryCode}}</span>
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- <pre>
                    {{products.deliveryOptions.countryOptions}}
                    </pre> -->
                    <div>
                      <div v-if="countryOptionsShipping" class="form-row" id="country-options-shipping">
                        <div class="row mb-3">
                          <div class="col-sm-12">
                            <!-- <b>Leveringsdestination</b> -->
                            <b>{{(labels.shippingOptionsMethod) ? labels.shippingOptionsMethod : 'shippingOptionsMethod (translation missing)'}}</b>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div v-for="(deliveryTypeCarrier, index) in countryOptionsShipping" :key="index" class="col-sm-5">
                            <input type="radio" @change="onchangeShop($event)" v-model="form.Carrier" name="Carrier" :value="deliveryTypeCarrier" :id="deliveryTypeCarrier" :class='"carrier-" + index'>&nbsp;
                            <!-- <label :for="deliveryTypeCarrier">{{ucfirst(returnPartOfString(deliveryTypeCarrier, 4, 11))}}</label> -->
                            <label :for="deliveryTypeCarrier">{{returnCarrierLabel(deliveryTypeCarrier)}}</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button @click="findCarrierShop" type="button" id="shop-postal-code-button" class="btn btn-success" style="display:none">{{labels.parcelShopSearch}}
                      <div class="lds-ring hide" id="shop-postal-code-button-spinner"><span></span><span></span><span></span><span></span></div>
                    </button>

                    <div v-if="this.carrierData != ''" class="mt-2" id="shop-postal-code-result">
                      <div v-if="this.carrierData.status == 200">
                        <div class="list-group my-1">
                          <div class="radio list-group-item" v-for="(carrier, index) in this.carrierData.data.parcelShops" :key="index" :class="{active:carrier.id == selected}" @click="selected = carrier.id">
                            <label :for="index" class="w-100">
                              <input :id="index" name="parcelshop" v-model="form.parcelshop" class="carrier" type="radio" :value="`${carrier.id}|${carrier.name}|${carrier.address}|${carrier.postalCode}|${carrier.city}|${carrier.countryCode}`" /> <span class="fw-bold">{{carrier.name}}</span>
                              <br />
                              {{carrier.address}}, {{carrier.postalCode}}, {{carrier.city}}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div v-if="this.carrierData.data.errorMessage" class="alert alert-warning" role="alert">
                        {{labels.parcelShopNotFound}}
                      </div>
                    </div>
                  </div>
                                      
                </div>

                <button type="submit" class="btn btn-primary me-3" data-loading-text="Godkender...">
                  {{labels.submit}}
                </button>
                <a href="javascript:window.history.back()" class="btn btn-outline-secondary">
                  {{labels.cancel}}
                </a>
                <input type="hidden" name="productNumber" :value="product.id" />
              </form>
              <br><br>
            </div>
          </div>

        </div>      

      </div> <!-- end if product -->
      <div v-else class="mt700">
        <div v-if="productLoading" class="loading-spinner" style="margin-bottom:500px"><div></div><div></div><div></div><div></div></div>
        <div v-else class="fade-in-fast">
          <div class="fade-in-slower d-flex justify-content-center">
            <div class="err-msg text-center text-light bg-error">{{t(getLang(), 'no-product')}}</div>
          </div>
        </div>
      </div> <!-- end else-->
    </div>

    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div v-if="this.modalData">
                  
                  <!-- success start -->
                  <div v-if="this.modalData.response == 'success'">
                    <div class="modal-header">
                      <h5 class="modal-title">{{labels.receiptHeader}}</h5>
                      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                      </button>
                    </div>
                    <div class="modal-body">
                      <p class="">{{labels.receiptBody}}</p>
                      <div class="row" v-for="(data, index) in this.modalData.registration" :key="index">
                        <div class="col">
                          <span v-if="data.label == 'giftCardCode'">
                            {{labels['giftcardCode']}}
                          </span>
                          <span v-else>
                            {{labels[data.label]}}
                          </span>
                        </div>
                        <div class="col-9">{{data.value}}</div>
                      </div>
                      <div v-if="this.modalData.privateShopUrl">
                        <h5 class="modal-title mt-4">{{labels.privateShopHeader}}</h5>
                        <p v-html="this.replaceToken(labels.privateShopBody, this.modalData.privateShopUrl, '{{PrivateShopUrl}}')"></p>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="showModal = false; goto('home')">Luk</button>
                    </div>
                  </div> 
                  <!-- success end -->
                  
                  <div v-else>
                    <div class="modal-header">
                      <!-- <h5 class="modal-title">{{labels.serverErrorHeader}}</h5> -->
                      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" @click="showModal = false"></button>
                    </div>
                    <div class="modal-body">
                      <!-- <p class="">{{labels.receiptBody}}</p> -->
                      <div class="row" v-for="(data, index) in this.modalData.errors" :key="index">
                        <div class="col">
                          <span v-if="data.id == 'GiftCardCode'">
                            {{labels['giftcardCode']}}
                          </span>
                          <span v-else>
                            {{labels[data.id]}}
                          </span>
                        </div>
                        <div class="col-9">{{labels[data.label]}}</div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="showModal = false; hideSpinner()">Luk</button>
                    </div>                      
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--button @click="showModal = true">Click</button-->

  </div>
</template>

<script>
/**
N1PM6M6D -
C7I8V946 -
ST3QZ584 -
Y440Q21G
CZJIWYD3
40FUH3D4
GM8N38K9
40UTO64A
0Z7NRD89
268ZJWDR
0W55UBX2
2560MU29
4EJE88KI
58U7KZR7 -
*/
import '@/assets/css/rs/royal-slider-custom.css';
import translations from '@/plugins/translations';
import utils from '@/plugins/utils'
import { mapState, mapActions } from "vuex";
import axios from "axios";
import $ from 'jquery';

export default {
  name: 'Checkout',
  data() {
    return{
      showModal: false,
      isActive: false,
      modalData: {},
      countryOptionsShipping: null,
      carrierData: '',
      selected: '',
      errors: [],
      form: {
        Attention: '',
        Carrier: '',
        Custom1: '',
        Custom2: '',
        Company: '',
        Email: '',
        EmployeeNumber: '',
        FullName: '',
        GiftCardCode: '',
        ShopCountry: '',
        ShopPostalCode: '',
        ShopStreet: '',
        TypeOfDelivery: null,
        Initials: '',
        CostCenter: '',
        Location: '',
        MobileNumber: '',
        parcelshop: '',

        ShippingOptions: '',
        ShippingOptionsType: '',
        ShippingAddress1: '',
        ShippingAddress2: '',
        ShippingCity: '',
        ShippingCountry: 'DK',
        ShippingName: '',
        ShippingPostalCode: '',
        
        Variants: ''
      }
    }
  },  
  props: ["id", "products", "labels"],
  computed: {
    // ...mapState("product", ["products", "product", "labels"]),
    ...mapState("product", ["product"]),
  },
  created() {
    this.loading = true;
    this.productLoading = true;
    let urlParams = new URLSearchParams(window.location.search);
    this.cur = urlParams.get('cur');
    this.lang = urlParams.get('lang');
    this.translations = translations;
  },  
  mounted() {
    this.getProduct(this.id),
    this.$nextTick(() => {
      this.loading = false;
      this.productLoading = false;
      this.initUtils();
      this.initWindowSizeCalc();
    })
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
    checkObject: utils.checkObject,
    getwindowWidth: utils.getWindowWidth,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,
    returnPartOfString: utils.returnPartOfString,
    replaceToken: utils.replaceToken,

    initWindowSizeCalc() {
      $(function() {
        setTimeout(function() {
          let _element = $('.checkout-details');
          let _window_height = $(window).height();
          let _app_height = $('#app').height();
          if (_window_height > _app_height) {
            let diff = _window_height - _app_height;
            let relative_height_padding = (100*diff/_app_height).toFixed(0);
            _element.css('padding-bottom', (diff - relative_height_padding) + 'px');
          }
        }, 100);
      });
    },

    setWithExpiry(key, data, ttl) {
      const now = new Date();
      const item = {
        data: data,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },  

    initUtils() {
      $(function() {
        setTimeout(function() {
          $('input[name=ShippingOptions]').change(function() {
            var val = $(this).val();
            // console.log(val);
            $('#shipping-company-private').show();
            if (val === 'company') {
              $('#shipping-specific-company').collapse("show");
              $('#shipping-specific-private').collapse("hide");
            } else {
              $('#shipping-specific-company').collapse("hide");
              $('#shipping-specific-private').collapse("show");
            }
          });        
        }, 1000);
      });

    },

    submitForm(e) {
      // Remove error message for registration.
      $('.error-registration').remove();
      console.log('FormData:');
      console.log(Object.fromEntries(new FormData(e.target)));
      // console.log(e.target.elements.Location.value);
      let {
        Attention,
        Carrier,
        Custom1,
        Custom2,
        Company,
        Email, 
        EmployeeNumber, 
        FullName, 
        GiftCardCode, 
        Initials,
        CostCenter,
        Location,
        MobileNumber,
        parcelshop,
        ShippingOptions,
        Address1, 
        Address2, 
        ShippingCity, 
        ShippingCountry, 
        ShippingName,
        PostalCode, 
        Variants
        } = Object.fromEntries(new FormData(e.target));

      let checkoutFields = {"checkoutFields": [
        {"id": "Address1", "value": (Address1) ? Address1 : ''},
        {"id": "Address2", "value": (Address2) ? Address2 : ''},
        {"id": "Attention", "value": (Attention) ? Attention : ''},
        {"id": "City", "value": (ShippingCity) ? ShippingCity : ''},
        {"id": "Country", "value": (ShippingCountry) ? ShippingCountry : ''},
        {"id": "Custom1", "value": (Custom1) ? Custom1 : ''},
        {"id": "Custom2", "value": (Custom2) ? Custom2 : ''},
        {"id": "Company", "value": (Company) ? Company : ''},
        {"id": "Email", "value": (Email) ? Email : ''},
        {"id": "EmployeeNumber", "value": (EmployeeNumber) ? EmployeeNumber : ''},
        {"id": "FullName", "value": (FullName) ? FullName : ''},
        {"id": "GiftCardCode", "value": (GiftCardCode) ? GiftCardCode : ''},
        {"id": "Initials", "value": (Initials) ? Initials : ''},
        {"id": "CostCenter", "value": (CostCenter) ? CostCenter : ''},
        {"id": "Location", "value": (Location) ? Location : ''},
        {"id": "MobileNumber", "value": (MobileNumber) ? MobileNumber : ''},
        {"id": "ShippingName", "value": (ShippingName) ? ShippingName : ''},
        {"id": "Name", "value": (ShippingName) ? ShippingName : ''},
        {"id": "ParcelShop", "value": (parcelshop) ? parcelshop : ''},
        {"id": "PostalCode", "value": (PostalCode) ? PostalCode : ''},
        {"id": "Product", "value": this.id},
        {"id": "ShippingOptions", "value": (Carrier) ? this.returnPartOfString(Carrier, 4, 11) : ''},
        {"id": "Variants", "value": (Variants) ? Variants : ''}
      ]};
      console.log('Checkout submit data:', checkoutFields);

      axios.post(`https://api-dev.metz.dk/giftshop/v1/${window.APP_API_CUSTOMER}/product/${this.id}`, JSON.stringify(checkoutFields))
        .then((res) => {
          
          /* Commit result to modal */
          // this.modalData = res.data;
          // this.showModal = true;

          if (typeof res.data.response != 'undefined' && res.data.response == 'error') {
            // List of fields to skip.
            const skipCheckOnField = [
              'Address2',
            ];

            console.log("fejl: ", res.data.errors);
            
            if (res.data.errors[0]['id'] == 'Registration') {
              $('.form-container').before('<div class="p-3 alert alert-danger error-registration">' + this.labels['registrationLocked'] + '</div>');
            }
            checkoutFields.checkoutFields.forEach(checkout_field => {
              // console.log(checkout_field.id);
              const c_field = $('[name="' + checkout_field.id + '"]');
              // Remove previous error message.
              $('.error-' + checkout_field.id).remove();
              // Check fields.
              if (!skipCheckOnField.includes(checkout_field.id)) {
                // Input field is valid. Input is not null and not empty.
                if (typeof c_field.val() != 'undefined' && c_field.val().length > 0) {
                  c_field.removeClass('is-invalid');
                  c_field.addClass('is-valid');
                }
                // Is invalid.
                else {
                  c_field.addClass('is-invalid');
                  // Display 'custom1Required' message block.
                  // c_field.after('<div class="p-1 alert text-danger error-' + checkout_field.id + '">' + this.labels['custom1Required'] + '</div>');
                }
                // Check if field is required from API.
                let required_field = this.flatten(this.products.checkoutFields, checkout_field.id)[checkout_field];
                if (!required_field) {
                  c_field.removeClass('is-invalid');
                }
              }
              // Check invalid fields against error response.
              res.data.errors.forEach(error_field => {
                /*
                // Return data to modal.
                if (error_field.id == 'Registration') {
                  this.modalData = res.data;
                  this.showModal = true;
                }
                */
                if (error_field.id == checkout_field.id) {
                  const e_field = $('[name="' + error_field.id + '"]');
                  e_field.addClass('is-invalid');
                  const errorText = (typeof this.labels[error_field.label] == 'undefined') ? error_field.label : this.labels[error_field.label];
                  e_field.after('<div class="p-1 alert text-danger error-' + error_field.id + '">' + errorText + '</div>');
                  this.scrollTo(e_field);
                }
              });
            });
          }
          else {
            console.log("OK: ", res.data);
            // this.modalData = res.data;
            // this.showModal = true;
            this.setWithExpiry(window.APP_API_CUSTOMER + '_confirmation', res.data, 1 * 60 * 1000);
            this.$router.push({name:'confirmation'}); 
          }
        })
        .catch((error) => {
          console.log({error});
        }).finally(() => {});
    },

    returnCarrierLabel(carrier) {
      const carriers = {
        "ups_company" : "shippingOptionsMethodUpsCompany",
        "ups_private" : "shippingOptionsMethodUpsPrivate",
        "ups_shop" : "shippingOptionsMethodUpsShop",
        "gls_company" : "shippingOptionsMethodGlsCompany",
        "gls_private" : "shippingOptionsMethodGlsPrivate",
        "gls_shop" : "shippingOptionsMethodGlsShop",
      };
      carrier = carrier.replace('-', '_');

      return (this.labels[carriers[carrier]]) ? this.labels[carriers[carrier]] : carriers[carrier];
    },

    findCarrierShop() {
      document.getElementById('shop-postal-code-button-spinner').style.display = 'inline-block';
      let postal_code_element = $('[name="PostalCode"]');
      // Remove any error message to postal code input field.
      postal_code_element.removeClass('is-invalid');
      $('.error-PostalCode').remove();

      let carriers = document.getElementsByName('Carrier');
      let parcelshop;
      carriers.forEach(e => {
        if (e.checked) {
          parcelshop = this.returnPartOfString(e.value.toLowerCase(), 0 ,3);
        }
      });
      let country = document.querySelector('[name="ShippingCountry"]').value;
      let PostalCode = document.querySelector('[name="PostalCode"]').value;
      let street = document.querySelector('[name="Address1"]').value;
      
      if (PostalCode.length == 0) {
        this.modalData = {"errors": [{"id": "postalCode", "label": "postalCodeRequired"}]};
        this.showModal = true;
        // Add error message to postal code input field.
        postal_code_element.addClass('is-invalid');
        postal_code_element.after('<div class="p-1 alert text-danger error-PostalCode">' + this.labels['custom1Required'] + '</div>');
        this.scrollTo(postal_code_element);
        return;
      }

      axios.get(`https://api-dev.metz.dk/giftshop/v1/${APP_API_CUSTOMER}/parcelShops?carrier=${parcelshop}&street=${street}&PostalCode=${PostalCode}&countryCode=${country}`)
        .then((res) => {
          this.carrierData = res;
        })
        .catch((error) => {
          console.log({error});
        }).finally(() => {
          document.getElementById('shop-postal-code-button-spinner').style.display = 'none';
        });
    },

    getSetTitleFromWindow() {
      const metaTitle = document.querySelector('head title');
      if (typeof window.APP_TITLE != 'undefined') {
        metaTitle.textContent = window.APP_TITLE + ' - BAXX & METZ';
      }
      else {
        metaTitle.textContent = this.getTitle();
      }
    },

    goto(ref) {
      // console.log(ref);
    },

    toggleClass: function(event){
       this.isActive = !this.isActive;
    },    

    flatten(list, key) {
      const flatObject = {};
      list.forEach(element => {
        if (key == element.id) {
          flatObject[element.id] = element.required;
        }
      });
      return flatObject;
    },

    // Location drop down.
    onchangeShippingOptions(event) {
      this.form.ShippingCountry = 'none';
      $('#country-options-shipping').hide();
      $('#shop-postal-code-button').hide();
      $('#shop-postal-code-result').hide();
      // Reset errors on fields onchange.
      $('#shipping-company-private').find('input').removeClass('is-invalid').next().remove();
    },

    // Location drop down.
    onchangeLocation(event) {
      if (event.target.value == 'direct-delivery') {
        $('#shipping-options').show();
      }
      else {
        $('#shipping-options').hide();
      }
    },

    // Location drop down.
    onchangeCountry(event, countryOptions) {
      let shippingCountry = event.target.value;
      let shippingOption = document.querySelector('[name="ShippingOptions"]:checked');
      if (shippingOption != null) {
        shippingOption = shippingOption.value;
        // Check first radio carrier option.
        setTimeout(() => {
          $('.carrier-0').click();
        }, 10);
        countryOptions.forEach(element => {
          if (shippingCountry == element.countryCode) {
            this.countryOptionsShipping = element[shippingOption];
          }
        });
      }
    },

    // Location drop down.
    onchangeShop(event) {
      let shopType = this.returnPartOfString(event.target.value, 4, 11);
      if (shopType == 'shop') {
        $('#shop-postal-code-button').show();
        $('#shop-postal-code-result').show();
      }
      else {
        $('#shop-postal-code-button').hide();
        $('#shop-postal-code-result').hide();
      }
    },

    getLocation(list, labels) {
      let rv = {};
      list.forEach(element => {
        if ('Location' == element.id) {
          if (typeof element.options != 'undefined') {
            element.options.forEach(option => {
              rv[option.value] = option.text || labels[option.label];
            });
          }
          else {
            rv = false;
          }
        }
      });
      return rv;
    },

    hasOptions(list, key) {
      let rv = {};
      list.forEach(element => {
        if (key == element.id) {
          rv = element.options;
        }
      });
      return rv;
    },

    isRequired(list, key) {
      let rv = false;
      list.forEach(element => {
        if (key == element.id) {
          rv = element.required;
        }
      });
      return rv;
    },

    showField(list, key) {
      let rv = false;
      if (typeof list == 'undefined') return;
      list.forEach(element => {
        if (key == element.id) {
          rv = true;
        }
      });
      return rv;
    },

    getProductTitle() {
      return this.product.title;
    },

    ucfirst(str) {
      var firstLetter = str.substr(0, 1);
      return firstLetter.toUpperCase() + str.substr(1);
    },

    // ...mapActions("product", ["getProducts", "getProduct", "getLabels"]),
    ...mapActions("product", ["getProduct"]),

    hideSpinner() {
      const spinner = document.getElementById('shop-postal-code-button-spinner');
      if (spinner != null) spinner.style.display = 'none';
    },

    // function scrollTo(selector) {
    //   document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
    // }
    scrollTo(field) {
      $([document.documentElement, document.body]).animate({
        scrollTop: field.offset().top - 25
      }, 100);
    }

  }
};
</script>

<style>
[v-cloak] {
  display: none;
}
.btn-outline-primary.lb-modal-prev,
.btn-outline-primary.lb-modal-next {
  background-color: #007bff50;
}
.btn-outline-danger.lb-modal-close {
  background-color: #dc354550;
}
.back {
  padding-left: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1' viewBox='0 0 50 50' enable-background='new 0 0 50 50'%3E%3Cpath style='text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans' d='M 30.28125 8.28125 L 14.28125 24.28125 L 13.59375 25 L 14.28125 25.71875 L 30.28125 41.71875 L 31.71875 40.28125 L 16.4375 25 L 31.71875 9.71875 L 30.28125 8.28125 z' overflow='visible' enable-background='accumulate' font-family='Bitstream Vera Sans'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}
.h-250 {
  height: 15.75rem !important;
}

.thumbs {
  padding-left: 0;
}

.thumb {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 150ms ease-out;  
}

.thumb img {
  padding: 4px 6px 6px 4px;
}

.thumb:hover {
  box-shadow: 0px 0px 10px 0 #ddd;
  transform: scale(1.02);
}

.thumbnail-badges {
  z-index: 1;
}

.thumb,
.thumb img {
  height: 295px;
  width: 295px;
}

.thumbs li:nth-child(n+3),
.thumbs li:nth-child(n+3) img {
  height: 105px;
  width: 105px;
}

.rsThumbsContainer {
  opacity:0;
}

.form-group {
  margin-bottom: 1rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

/* style all input elements with a required attribute */
/* input:required,
select:required
{
  border: 1px solid #000;
} */

/**
 * style input elements that have a required
 * attribute and a focus state
 */
input:required:focus {
  border: 1px solid red;
  /* box-shadow: 1px 1px 5px rgba(179, 176, 176, 0.85); */
  /* outline: 2px solid red; */
}

.parcelshop:checked {
  background-color: #007bff;
}

#shop-postal-code-result .list-group-item {
  transition: all 150ms ease-in-out;
}

#shop-postal-code-result .list-group-item:hover {
  transform: scale(1.005);
}

#shop-postal-code-result .list-group-item label {
  cursor: pointer;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}
.lds-ring span {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 1px;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring span:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring span:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring span:nth-child(3) {
  animation-delay: -0.15s;
}
.pb-xxl {
  padding-bottom:100px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hide {
  display: none;
}

@media (max-width: 768px) {

}
</style>