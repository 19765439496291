import axios from "axios";

const Api = axios.create({
    baseURL: window.APP_API_ENDPOINT,
    withCredentials: false,
    validateStatus: function (status) {
      return status;
    }    
});

export default Api;