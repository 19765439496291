export const SET_PRODUCTS = (state, products) => {
    state.products = products;
}

export const SET_PRODUCT = (state, product) => {
    state.product = product;
}

export const SET_STATUS = (state, status) => {
    state.status = status;
}

export const SET_LABELS = (state, labels) => {
    state.labels = labels;
}
