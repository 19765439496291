<template>
  <div :class="products.theme" class="home"> 
    {{getSetTitleFromWindow()}}
    <div v-if="noOffer"></div>

    <div v-if="loading" class="loading-spinner"><div></div><div></div><div></div><div></div></div>
    <div v-else class="fade-in-fast">

      <div v-if="products.responseCode != 404 && products.responseCode != 403 && products.status == 'open'" class="bg1" :class="{'c2' : products.theme == 'xmas', 'c2' : products.theme == 'merchandise'}" v-cloak>
        <div class="container overview">
          <div class="row mx-auto pt-3 pb-3">
            <div class="col-sm-6 mt-3 pl-0 fs2">
              <p v-if="labels" v-html="labels.welcomeMessageBody"></p>
              <p v-if="labels" v-html="labels.welcomeMessageFooter"></p>
              <div v-if="products.logoUrl">
                <img v-if="products.logoUrl.length > 24" :src="products.logoUrl" />
              </div>
            </div>
            <div class="col-sm-6 d-flex align-items-top">
              <h1 v-if="labels" class="w100" style="text-align:right">{{labels.welcomeMessageHeader}} </h1>
            </div>
          </div> <!-- end if product -->
        
        </div>
      </div>
    
    </div> <!-- end loading else -->
    
    <div class="container overview">
      <div v-if="products.responseCode != 404 && products.responseCode != 403 && products.status != 'pending' && products.status != 'closed'" class="row mx-auto pb-3">
        <span>&nbsp;</span>
      </div>
      <!-- <div v-if="Object.keys(products).length == 0" class="ms-3 fade-in-slower" v-cloak> -->
      <div v-if="products.responseCode == 404 || products.responseCode == 403" class="fade-in-fast" v-cloak>
        <div class="mx-auto _text-light _text-center _err-msg _bg-error">
          
          <div v-if="products.responseCode == 404">
            <h1 v-html="labels.pageNotFoundHeader"></h1>
            <p v-html="labels.pageNotFoundBody" class="fade-in-slow"></p>
          </div>
          <div v-else-if="products.responseCode == 403">
            {{products.responseData}}
          </div>

          <div v-if="products.status == 'pending'">
            <h1 v-html="labels.shopOpensHeader"></h1>
            <p v-html="labels.shopOpensBody" class="fade-in-slow"></p>
          </div>

          <div v-if="products.status == 'closed'">
            <h1 v-html="labels.shopClosedHeader"></h1>
            <p v-html="labels.shopClosedBody" class="fade-in-slow"></p>
          </div>

          <br><br>
        </div>
      </div>

      <div v-if="products.status == 'pending'">
        <h1 v-html="labels.shopOpensHeader"></h1>
        <p v-html="labels.shopOpensBody" class="fade-in-slow"></p>
      </div>

      <div v-if="products.status == 'closed'">
        <h1 v-html="labels.shopClosedHeader"></h1>
        <p v-html="labels.shopClosedBody" class="fade-in-slow"></p>
      </div>

      <div v-if="products.status == 'open'" class="other-products fade-in-slower">
        <div class="row mx-auto pb-3">
          <h3 class="mt-4 pl-0">{{t(getLang(), 'products')}}</h3>
        </div>
        <!-- Thumbnails -->
        <thumbnails :products="products" :labels="labels" />
      </div> <!-- status open -->
      <br><br>
    </div>

  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import translations from '@/plugins/translations';
import utils from '@/plugins/utils'
import Thumbnails from '@/components/Thumbnails';

export default {
  props: ['products', 'labels'],
  data: function() {
    // let confirmation = this.getWithExpiry(window.APP_API_CUSTOMER + '_confirmation');

    return {
      noOffer: false,
      apiError: false,
      apiResponse: window.APP_RESPONSE,
    }
  },
  components: {
    Thumbnails
  },
  computed: {
    // ...mapState("product", ["products", "labels"]),
  },
  created() {
    this.loading = true;
    let urlParams = new URLSearchParams(window.location.search);
    this.lang = (urlParams.get('lang') == null) ? window.APP_API_LANG : urlParams.get('lang');
    this.cur = (urlParams.get('cur') == null) ? window.APP_API_CUR : urlParams.get('cur');
    this.translations = translations;

  },  
  mounted() {
    this.$nextTick(() => {
      // this.noOffer = window.APP_NO_OFFER;
      this.loading = false;
      this.checkOffer();
      this.checkError();
      this.checkResponse();
      this.initWindowSizeCalc();
    })
  },
  mutations: {
  },
  methods: {
    getLang: utils.getLang,
    t: utils.t,
    getAttribute: utils.getAttribute,
    formatNumber: utils.formatNumber,
    getTitle: utils.getTitle,
    setTitle: utils.setTitle,

    initWindowSizeCalc() {
      $(function() {
        setTimeout(function() {
          let _window_height = $(window).height();
          let _app_height = $('#app').height();
          if (_window_height > _app_height) {
            let diff = _window_height - _app_height;
            let relative_height_padding = (100 * diff/_app_height).toFixed(0);
            $('.other-products').css('padding-bottom', ((diff - relative_height_padding)) + 'px');
          }
        }, 300);
      });
    },

    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) return null;
      const item = JSON.parse(itemStr);
      const now = new Date();
        window.local_storage_expiry = item.expiry;
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.data;
    },  

    getSetTitleFromWindow() {
      const metaTitle = document.querySelector('head title');
      if (typeof window.APP_TITLE != 'undefined') {
        metaTitle.textContent = window.APP_TITLE + ' - BAXX & METZ';
      }
      else {
        metaTitle.textContent = this.getTitle();
      }
    },

    formatDate(_date) {
      let d = new Date(_date);
      if (this.getAttribute('lang') == 'en') {
        d = d.toLocaleString("en-GB")
      }
      else {
        d = d.toLocaleString("da-DK");
        d = d.split(" ");
        d = d[0];
      }
      return d;
    },

    checkOffer() {
      this.noOffer = window.APP_NO_OFFER;
      // console.log('APP_NO_OFFER:', this.noOffer);
    },

    checkError() {
      this.apiError = window.APP_API_ERROR;
      // console.log('APP_API_ERROR:', this.apiError);
    },

    checkResponse() {
      this.apiResponse = window.APP_RESPONSE;
      // console.log('APP_RESPONSE:', this.apiResponse);
    },

    // ...mapActions("product", ["getProducts", "getLabels"])
  }
};
</script>

<style>
.xmas-xtra h1,
.xmas h1 {
  font-family: 'Abril Fatface'!important;
}

.home.xmas .container.overview,
.home.xmas-xtra .container.overview {
  background-color: transparent;
}

.home.xmas,
.home.xmas h3,
.home.xmas-xtra,
.home.xmas-xtra h3,
.c2 .container {
  color: #fff;
}
@keyframes color {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #dc3545;
  }
}

@media (max-width: 768px) {
  .row.other-products {
    margin: 0;
  }
  h3 {
    margin-left: 15px;
  }
}
[v-cloak] {
  display: none;
}
</style>