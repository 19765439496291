<template>
  <div id="app">
    <app-header :products="products"/>
    <router-view :products="products" :labels="labels"></router-view>
    <app-footer :products="products" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

export default {
  name: "app",
  data() {
    return {
    }
  },
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    ...mapState("product", ["products", "labels"])
  },
  mounted () {
    this.getProducts(),
    this.getLabels()
  },
  methods: {
    ...mapActions("product", ["getProducts", "getLabels"]),
  }
};
</script>
<style>
@import "../public/css/styles.css";
@import "../public/css/bootstrap-ie11.min.css";
</style>